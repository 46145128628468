import { createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/local';

import { clientStarted } from '@/shared/lib/startup';

import { getTotalExpForLevel } from './lib';
import { User } from './types';

export const $user = createStore({
  id: 0,
  name: 'Anon',
  email: 'example@gmail.com',
  level: 1,
  exp: 20,
});

export const setUser = createEvent<User>();

sample({
  clock: setUser,
  target: $user,
});

export const $userLevel = $user.map((user) => user.level);
export const $userExp = $user.map((user) => user.exp);
export const $userName = $user.map((user) => user.name);

export const $totalExpForLevelUp = $user.map((user) => getTotalExpForLevel(user.level));

export const $levelUpProgress = $user.map((user) => {
  const expForLevelUp = getTotalExpForLevel(user.level);

  return (user.exp / expForLevelUp) * 100;
});

export const increaseExp = createEvent<number>();
export const decreaseExp = createEvent<number>();

sample({
  clock: increaseExp,
  source: $user,
  fn: (user, exp) => {
    let newExp = user.exp + exp;
    let newLevel = user.level;

    let isLeveledUp = newExp > getTotalExpForLevel(newLevel);

    while (isLeveledUp) {
      const expForLevelUp = getTotalExpForLevel(newLevel);
      newExp -= expForLevelUp;
      newLevel += 1;

      isLeveledUp = newExp > getTotalExpForLevel(newLevel);
    }

    return { ...user, exp: newExp, level: newLevel };
  },
  target: $user,
});

sample({
  clock: decreaseExp,
  source: $user,
  fn: (user, exp) => {
    let newExp = user.exp - exp;
    let newLevel = user.level;

    let isLeveledDown = newExp < 0;

    while (isLeveledDown) {
      const expForLevelDown = getTotalExpForLevel(newLevel - 1);
      newExp += expForLevelDown;
      newLevel -= 1;

      isLeveledDown = newExp < 0;
    }

    return { ...user, exp: newExp, level: newLevel };
  },
  target: $user,
});

persist({
  store: $user,
  key: 'user',
  pickup: clientStarted,
});
