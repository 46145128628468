import { Url } from 'next/dist/shared/lib/router/router';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RiArrowLeftDoubleLine, RiNotificationLine } from 'react-icons/ri';

import { MaehwaLogo } from '@/shared/components/maehwa-logo';
import { Button } from '@/shared/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/shared/components/ui/card';
import { cn } from '@/shared/lib/cn';
import { APP_TITLE } from '@/shared/lib/seo';

import { links } from '../const';

export interface SidebarItemProps {
  href: Url;
  children: React.ReactNode;
}

export const SidebarLink = (props: SidebarItemProps) => {
  const { href, children } = props;

  const { asPath } = useRouter();

  const pathname = typeof href === 'object' ? href.pathname : href;

  const isActive = asPath === pathname;

  return (
    <Link
      href={href}
      className={cn(
        'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary',
        isActive && 'bg-muted text-primary',
      )}
    >
      {children}
    </Link>
  );
};

export const Sidebar = () => {
  return (
    <div className="hidden border-r md:block">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
          <Link href="/" className="flex items-center gap-2 font-semibold">
            <MaehwaLogo className="h-6 w-6" />
            <span className="capitalize">{APP_TITLE}</span>
          </Link>

          <Button variant="outline" size="icon" className="ml-auto h-8 w-8">
            <RiNotificationLine className="h-4 w-4" />
            <span className="sr-only">Toggle notifications</span>
          </Button>
        </div>

        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            {links.map((link) => (
              <SidebarLink key={link.label} href={link.href}>
                {/* <RiHomeLine className="h-4 w-4" /> */}
                {link.label}
              </SidebarLink>
            ))}
          </nav>
        </div>

        <div className="mt-auto p-4">
          {/* <Button variant="outline" className="w-full">
            Help
          </Button> */}

          <Card x-chunk="dashboard-02-chunk-0">
            <CardHeader className="p-2 pt-0 md:p-4">
              <CardTitle>Upgrade to Pro</CardTitle>
              <CardDescription>Unlock all features and get unlimited access to our support team.</CardDescription>
            </CardHeader>
            <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
              <Button size="sm" className="w-full">
                Upgrade
              </Button>
            </CardContent>
          </Card>

          <Button variant="ghost" className="mt-2 w-full justify-end">
            <RiArrowLeftDoubleLine className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};
