import { parseCronExpression } from 'cron-schedule';
import {
  isAfter,
  isBefore,
  isSameDay,
  isThisYear,
  isToday,
  isWithinInterval,
  startOfToday,
  startOfYesterday,
} from 'date-fns';
import { combine, createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage/local';
import { last, randomInt } from 'es-toolkit';
import { nanoid } from 'nanoid';

import { decreaseExp, increaseExp } from '@/entities/users/@x/tasks';

import { confettiFromScreenEdgesFx } from '@/shared/lib/confetti';
import { clientStarted } from '@/shared/lib/startup';

import { ActiveTask, DailyActivity, Task } from './types';

export const $tasks = createStore<Task[]>([
  // {
  //   id: '1',
  //   title: 'Do some work on the project',
  //   description: 'Create initial version of the project',
  //   completed: false,
  //   fromDate: startDay,
  //   schedule: '0 0 * * *',
  //   exp: 150,
  // },
  // {
  //   id: '2',
  //   title: 'Take vitamins',
  //   description: 'Take vitamins and supplements',
  //   completed: false,
  //   fromDate: startDay,
  //   schedule: '0 0 * * *',
  //   exp: 25,
  //   toDate: addDays(startDay, 7),
  // },
  // {
  //   id: '3',
  //   title: 'Do gym',
  //   description: 'Create initial version of the project',
  //   schedule: '0 0 * * 1,3,5',
  //   completed: false,
  //   fromDate: startDay,
  //   exp: 50,
  // },
  // ...range(0, 4).map((i) => ({
  //   id: `${i + 2}`,
  //   title: `Task ${i + 2}`,
  //   description: 'Create initial version of the project',
  //   exp: randomInt(10, 100),
  //   schedule: '0 0 * * *',
  //   completed: Math.random() > 0.5,
  //   fromDate: subDays(startDay, 7),
  //   toDate: addDays(startDay, 7),
  // })),
]);

export const $activeTasks = combine($tasks, (tasks) => {
  const today = startOfToday();
  const activeTasks = tasks.filter((task) => {
    if (!task.toDate) {
      return isSameDay(today, task.fromDate) || isAfter(today, task.fromDate);
    }

    return isWithinInterval(today, {
      start: task.fromDate,
      end: task.toDate,
    });
  });

  return activeTasks;
});

export const $expiredTasks = combine($tasks, (tasks) => {
  const today = startOfToday();
  const activeTasks = tasks.filter((task) => {
    if (!task.toDate) {
      return false;
    }

    return isAfter(today, task.toDate);
  });

  return activeTasks;
});

export const $futureTasks = combine($tasks, (tasks) => {
  const today = startOfToday();
  const activeTasks = tasks.filter((task) => {
    if (!task.toDate) {
      return false;
    }

    return isBefore(today, task.toDate);
  });

  return activeTasks;
});

export const $todayTasks = combine($activeTasks, (activeTasks) => {
  const today = startOfToday();

  const todaysTasks = activeTasks.filter((task) => {
    const cron = parseCronExpression(task.schedule);

    return cron.matchDate(today);
  });

  return todaysTasks;
});

export const taskToggled = createEvent<Task>();
export const taskEdited = createEvent<Task>();
export const taskAdded = createEvent<Omit<Task, 'id'>>();
export const taskRemoved = createEvent<Task>();

sample({
  clock: taskAdded,
  source: $tasks,
  fn: (tasks, task) => {
    const newTask = {
      ...task,
      exp: randomInt(10, 50),
      id: nanoid(),
    };

    return [...tasks, newTask];
  },
  target: $tasks,
});

sample({
  clock: taskEdited,
  source: $tasks,
  fn: (tasks, task) => {
    const newTasks = tasks.map((t) => {
      if (t.id === task.id) {
        return {
          ...t,
          ...task,
        };
      }

      return t;
    });

    console.log(newTasks);

    return newTasks;
  },
  target: $tasks,
});

export const $activity = createStore<DailyActivity[]>([
  {
    date: startOfYesterday(),
    tasks: [
      { taskId: '1', completed: true },
      { taskId: '2', completed: false },
    ],
  },
]);

sample({
  clock: taskToggled,
  source: $activity,
  fn: (activities, task) => {
    const newActivities = [...activities];
    const todaysActivity = last(activities)!;
    const newTasks: ActiveTask[] = [];

    todaysActivity?.tasks.forEach((t) => {
      if (t.taskId === task.id) {
        newTasks.push({ ...t, completed: !t.completed });
      } else {
        newTasks.push(t);
      }
    });

    newActivities.pop();

    return [
      ...newActivities,
      {
        ...todaysActivity,
        tasks: newTasks,
      },
    ];
  },
  target: $activity,
});

sample({
  clock: taskToggled,
  filter: (task) => !task.completed,
  fn: (task) => task.exp,
  target: increaseExp,
});

sample({
  clock: taskToggled,
  filter: (task) => task.completed,
  fn: (task) => task.exp,
  target: decreaseExp,
});

sample({
  clock: $todayTasks,
  source: $activity,
  fn: (activities, todayTasks) => {
    const newActivities = [...activities];
    const todaysActivity = last(activities)!;

    if (!isToday(todaysActivity?.date)) {
      return [
        ...activities,
        {
          date: startOfToday(),
          tasks: todayTasks.map((task) => ({ taskId: task.id, completed: false })),
        },
      ];
    }

    const lastActivity = newActivities.pop()!;
    const newTasks: ActiveTask[] = [];

    todayTasks.forEach((task) => {
      const taskExists = lastActivity?.tasks.find((todayTask) => todayTask.taskId === task.id);

      if (taskExists) {
        newTasks.push({ taskId: task.id, completed: taskExists.completed });
      }

      if (!taskExists) {
        newTasks.push({ taskId: task.id, completed: false });
      }
    });

    return [
      ...newActivities,
      {
        ...lastActivity,
        tasks: newTasks,
      },
    ];
  },
  target: $activity,
});

export const $todaysActivity = combine($activity, $tasks, (activities, tasks) => {
  const lastActivity = last(activities);

  if (!lastActivity) {
    return { date: startOfToday(), tasks: [] };
  }

  return {
    ...lastActivity,
    tasks: lastActivity.tasks
      .map((task) => {
        const foundTask = tasks.find((t) => t.id === task.taskId);

        if (!foundTask) {
          return null;
        }

        return {
          ...foundTask,
          completed: task.completed,
        };
      })
      .filter(Boolean),
  };
});

export const $todayTasksAmount = combine($todaysActivity, (activity) => activity.tasks.length);
export const $todayTasksCompleted = combine(
  $todaysActivity,
  (activity) => activity.tasks.filter((t) => t.completed).length,
);

export const allTasksCompletedForToday = sample({
  clock: taskToggled,
  source: {
    total: $todayTasksAmount,
    completed: $todayTasksCompleted,
  },
  filter: ({ total, completed }) => {
    return total === completed;
  },
});

sample({
  clock: allTasksCompletedForToday,
  target: confettiFromScreenEdgesFx,
});

// Analitycs

export const $tasksCompletedForYear = combine($activity, (activity) => {
  return activity
    .filter((a) => isThisYear(a.date))
    .map((a) => a.tasks.filter((t) => t.completed).length)
    .reduce((sum, current) => sum + current, 0);
});

persist({
  store: $activity,
  key: 'activity',
  pickup: clientStarted,
});

persist({
  store: $tasks,
  key: 'tasks',
  pickup: clientStarted,
});
