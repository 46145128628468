import { format } from 'date-fns';

import { Task } from '@/entities/tasks';

export const getTaskTimelineString = (task: Task) => {
  const { fromDate, toDate } = task;

  const from = format(fromDate, 'dd.MM.yy');
  const to = toDate ? format(toDate, 'dd.MM.yy') : '∞';

  return `${from} -> ${to}`;
};
