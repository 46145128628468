import { Header } from './ui/header';
import { Sidebar } from './ui/sidebar';

export interface AppLayoutProps {
  children?: React.ReactNode;
}

export function AppLayout(props: AppLayoutProps) {
  const { children } = props;

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
      <Sidebar />

      <div className="flex flex-col">
        <Header />

        <main className="flex flex-1 flex-col gap-4 py-6">{children}</main>
      </div>
    </div>
  );
}
