import React from 'react';
import { RiMoreLine } from 'react-icons/ri';

import { Button } from '@/shared/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/components/ui/dialog';

import { TaskEditForm, TaskEditFormProps } from './task-edit-form';

export interface TaskEditModalButtonProps extends TaskEditFormProps {
  children?: React.ReactNode;
}

export const TaskEditModalButton = (props: TaskEditModalButtonProps) => {
  const { task, children } = props;

  const [open, setOpen] = React.useState(false);

  const modalTitle = task ? 'Edit task' : 'Create task';

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children ?? (
          <Button size="icon-xs" variant="ghost">
            <RiMoreLine className="h-4 w-4" />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{modalTitle}</DialogTitle>
          <DialogDescription>Make changes to your task here. Click save when you are done.</DialogDescription>
        </DialogHeader>

        <TaskEditForm onSave={() => setOpen(false)} task={task} />
      </DialogContent>
    </Dialog>
  );
};
