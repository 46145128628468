import confetti from 'canvas-confetti';
import { createEffect } from 'effector';
import { random } from 'es-toolkit';

export const confettiApi = confetti.create(undefined, {
  resize: true,
  disableForReducedMotion: true,
});

export const confettiFromScreenEdges = () => {
  confettiApi({
    angle: 30,
    spread: 120,
    origin: { x: 0 },
  });

  return confettiApi({
    angle: 150,
    spread: 120,
    origin: { x: 1 },
  });
};

export const confettiFireworks = () => {
  const duration = 5 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60 };

  const interval = window.setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);
    confettiApi({
      ...defaults,
      particleCount,
      origin: { x: random(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confettiApi({
      ...defaults,
      particleCount,
      origin: { x: random(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
};

export const confettiFromScreenEdgesFx = createEffect(confettiFromScreenEdges);
export const confettiFireworksFx = createEffect(confettiFireworks);
