import { format } from 'date-fns';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';
import { RiCalendarLine } from 'react-icons/ri';

import { Task, taskAdded, taskEdited } from '@/entities/tasks';

import { Button } from '@/shared/components/ui/button';
import { Calendar } from '@/shared/components/ui/calendar';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/shared/components/ui/form';
import { Input } from '@/shared/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/components/ui/popover';
import { Textarea } from '@/shared/components/ui/textarea';
import { cn } from '@/shared/lib/cn';

export interface TaskEditFormProps {
  task?: Task;
  onSave?: (task: Task) => void;
}

export const TaskEditForm = (props: TaskEditFormProps) => {
  const { task, onSave } = props;

  const { editTask, addTask } = useUnit({
    editTask: taskEdited,
    addTask: taskAdded,
  });

  const form = useForm<Task>({
    defaultValues: {
      ...task,
      title: task?.title ?? '',
      description: task?.description ?? '',
      schedule: task?.schedule ?? '',
      toDate: task?.toDate,
      fromDate: task?.fromDate,
    },
  });

  const onTaskSave = (data: Task) => {
    if (task) {
      editTask(data);
    } else {
      addTask(data);
    }

    onSave?.(data);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onTaskSave)} className="space-y-4">
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title</FormLabel>
                <FormControl>
                  <Input placeholder="Walk the dog" {...field} />
                </FormControl>
                <FormDescription>This is your public display name.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea rows={3} placeholder="Task description..." {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div>
            <FormField
              control={form.control}
              name="schedule"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Schedule</FormLabel>
                  <FormControl>
                    <Input placeholder="* * * * *" {...field} />
                  </FormControl>
                  <FormMessage />
                  <FormDescription>Specify the cron-like schedule.</FormDescription>
                </FormItem>
              )}
            />
          </div>

          <div className="flex space-x-4">
            <FormField
              control={form.control}
              name="fromDate"
              render={({ field }) => (
                <FormItem className="flex w-full flex-col">
                  <FormLabel>Start date</FormLabel>

                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={'outline'}
                          className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                        >
                          <span className="mr-4 flex">{field.value ? format(field.value, 'PPP') : 'Pick a date'}</span>

                          <RiCalendarLine className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>

                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar mode="single" selected={new Date(field.value)} onSelect={field.onChange} initialFocus />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="toDate"
              render={({ field }) => (
                <FormItem className="flex w-full flex-col">
                  <FormLabel>End date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={'outline'}
                          className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                        >
                          <span className="mr-4 flex">{field.value ? format(field.value, 'PPP') : 'Pick a date'}</span>
                          <RiCalendarLine className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value ? new Date(field.value) : undefined}
                        onSelect={field.onChange}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="flex justify-end">
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Form>
  );
};
